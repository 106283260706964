<template>
  <!-- 
    画面: 部屋タイプ編集  削除タブ
    用途: 部屋タイプを削除する
   -->
  <div>

    <!-- #region 削除ボタン -->
    <CButton
      class="px-5 mt-4"
      color="danger"
      @click="onDeleteClicked()"
    >
      <i class="cil-trash"></i> {{ $t("common.delete") }}
    </CButton>
    <!-- #endregion 削除ボタン -->

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RoomTypeEditDelete",

  computed: {
    roomTypeId() {
      return this.$route.params.roomTypeId;
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      //#endregion Flag


      //#region Response data
      response: [],
      //#endregion Response data
    }
  },

  methods: {
    //#region Event
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    onDeleteSubmit() {
      this.reqDelete();
    },
    //#endregion Event


    //#region Request
    reqDelete() {
      this.loading = true;

      const url = `/rest/hotel/${this.uid}/room/${this.roomTypeId}`;

      axios
        .delete(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.loading = false;
          this.deleteConfirmModal = false;
          this.successModal = true;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
        });
    },
    //#endregion Request


    //#region Method
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

};
</script>

<template>
  <!-- 
    画面: 部屋タイプ編集  基本設定タブ
    用途: 部屋タイプの基本設定を編集する
   -->
  <div>

    <!-- #region 識別子 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("roomTypeEditPage.identifier") }}</strong></CCol>
      <CCol md="2">
        <CInput v-model="edit.identifier" />
        <p
          class="text-danger input-info"
          v-if="$v.edit.identifier.$dirty && !$v.edit.identifier.required"
        >
          {{ $t("validations.required", { vName: $t("roomTypeEditPage.identifier") })}}
        </p>
      </CCol>
    </CRow>
    <!-- #endregion 識別子 -->

    <!-- #region 名称 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("roomTypeEditPage.name") }}</strong></CCol>
      <CCol>
        <CInput v-model="edit.name" />
        <p
          class="text-danger input-info"
          v-if="$v.edit.name.$dirty && !$v.edit.name.required"
        >
          {{ $t("validations.required", { vName: $t("roomTypeEditPage.name") })}}
        </p>
      </CCol>
    </CRow>
    <!-- #endregion 名称 -->

    <!-- #region 定員 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("roomTypeEditPage.capacity") }}</strong></CCol>
      <CCol md="1">
        <CInput
          v-model="edit.min"
          type="number"
        />
      </CCol>
      ~
      <CCol md="1">
        <CInput
          v-model="edit.max"
          type="number"
        />
      </CCol>
      {{ $t("common.personUnit") }}
    </CRow>

    <!-- #region Validation Error -->
    <CRow>
      <CCol class="label"></CCol>
      <CCol>
        <p
          class="text-danger input-info"
          v-if="$v.edit.min.$dirty && !$v.edit.min.required"
        >
          {{ $t("validations.required", { vName: $t("roomTypeEditPage.capacityMin") })}}
        </p>
        <p
          class="text-danger input-info"
          v-if="$v.edit.max.$dirty && !$v.edit.max.required"
        >
          {{ $t("validations.required", { vName: $t("roomTypeEditPage.capacityMax") })}}
        </p>
        <p
          class="text-danger input-info"
          v-if="$v.edit.max.$dirty && !$v.edit.max.minValue"
        >
          {{ $t("validations.minValue", { vName: $t("roomTypeEditPage.capacityMax"), vMin: `${edit.min}名` })}}
        </p>
      </CCol>
    </CRow>
    <!-- #endregion Validation Error -->
    <!-- #endregion 定員 -->

    <!-- #region 基準在庫数 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("roomTypeEditPage.baseInventory") }}</strong></CCol>
      <CCol md="1">
        <CInput
          v-model="edit.inventory"
          type="number"
        />
      </CCol>
    </CRow>

    <!-- #region Validation Error -->
    <CRow>
      <CCol class="label"></CCol>
      <CCol>
        <p
          class="text-danger input-info"
          v-if="$v.edit.inventory.$dirty && !$v.edit.inventory.required"
        >
          {{ $t("validations.required", { vName: $t("roomTypeEditPage.baseInventory") })}}
        </p>
      </CCol>
    </CRow>
    <!-- #endregion Validation Error -->
    <!-- #endregion 基準在庫数 -->

    <!-- #region 部屋の広さ -->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.size") }}</strong></CCol>
      <CCol md="1">
        <CInput
          v-model="edit.size"
          type="number"
        />
      </CCol>
      <CCol md="2">
        <CSelect
          :value.sync="edit.sizeUnit"
          :options="RoomFloorTypeSelection"
        />
      </CCol>
    </CRow>
    <!-- #endregion 部屋の広さ -->

    <!-- #region 説明 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.description") }}</strong></CCol>
      <CCol>
        <CTextarea
          class="mb-2"
          rows="5"
          v-model="edit.description"
        />
      </CCol>
    </CRow>
    <!-- #endregion 説明 -->

    <!-- #region 画像 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.image") }}</strong></CCol>
      <CCol>
        <ImageSelectModal
          :images="edit.images"
          @selected-images="edit.images = $event"
        />
      </CCol>
    </CRow>
    <!-- #endregion 画像 -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="info"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import axios from "axios";
import ImageSelectModal from "@/components/ImageSelectModal.vue";
import RoomFloorTypeSelection from "@/mixins/property";

import {
  required,
  minValue,
  // maxValue,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "RoomTypeEditBasic",

  mixins: [RoomFloorTypeSelection],

  components: {
    ImageSelectModal,
  },

  props: {},

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Edit
      edit: {},
      //#endregion Edit


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Message
      errorMessage: "",
      //#endregion Message

    };
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    roomTypeId() {
      return this.$route.params.roomTypeId;
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  validations() {
    return {
      edit: {
        identifier: { required },
        name: { required },
        min: { required },
        max: { required, minValue: minValue(this.edit.min) },
        inventory: { required },
        size: {},
        description: {},
      },
    };
  },

  methods: {
    //#region Event
    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },
    //#endregion Event


    //#region Request
    reqGet() {
      this.loading = true;

      const url = `/rest/hotel/${this.uid}/room/${this.roomTypeId}`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          this.edit = resp.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    reqPut() {
      this.loading = true;

      const url = `/rest/hotel/${this.uid}/room/${this.roomTypeId}`;
      const body = this.edit;

      axios
        .put(url, body, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.edit = resp.data;
          this.loading = false;
          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.PassError = err.response.status;
          this.errorModal = true;
        });
    },
    //#endregion Request
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>

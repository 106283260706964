<template>
  <!-- 
    画面: 部屋タイプ編集
    用途: 部屋タイプを更新または削除する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("roomTypeEditPage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form ">

              <CTabs
                class="edit_tab"
                fade
                :active-tab="$store.state.roomTypeEditActiveTab"
              >

                <!-- #region 基本設定 -->
                <CTab
                  :title="$t('roomTypeEditPage.tab0')"
                  @click.native="activeTab(0)"
                >
                  <RoomTypeEditBasic />
                </CTab>
                <!-- #endregion 基本設定 -->

                <!-- #region 掲載設定 -->
                <CTab
                  :title="$t('roomTypeEditPage.tab1')"
                  @click.native="activeTab(1)"
                >
                  <RoomTypeEditPosting />
                </CTab>
                <!-- #endregion 掲載設定 -->

                <!-- #region 削除 -->
                <CTab
                  :title="$t('roomTypeEditPage.tab2')"
                  @click.native="activeTab(2)"
                >
                  <RoomTypeEditDelete />
                </CTab>
                <!-- #endregion 削除 -->

              </CTabs>

            </CForm>
          </CCardBody>

          <CCardFooter>

            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->

          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

  </div>
</template>

<script>
// import axios from "axios";
import RoomTypeEditBasic from "./components/EditBasic";
import RoomTypeEditPosting from "./components/EditPosting";
import RoomTypeEditDelete from "./components/EditDelete";

export default {
  name: "RoomTypeEdit",

  components: {
    RoomTypeEditBasic,
    RoomTypeEditPosting,
    RoomTypeEditDelete,
  },

  methods: {
    //#region Event
    onBackClicked() {
      this.backPage();
    },
    //#endregion Event


    //#region Method
    activeTab(TabNo) {
      this.$store.commit("set", ["roomTypeEditActiveTab", TabNo]);
    },

    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },
};
</script>

<template>
  <!-- 
    画面: 部屋タイプ編集  掲載設定タブ
    用途: 部屋タイプの掲載設定を編集する
   -->
  <div>

    <!-- #region Form -->
    <!-- #region 部屋分類 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.kind") }}</strong></CCol>
      <CCol>
        <div class="form-inline">
          <CInputCheckbox
            class="mx-1"
            v-for="(option, n) in roomTypeList"
            :key="n + option"
            :label="option.feature.name"
            :value="option.feature.id"
            custom
            :checked.sync="option.enabled"
          />
        </div>
      </CCol>
    </CRow>
    <!-- #endregion 部屋分類 -->

    <!-- #region 部屋特徴 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.feature") }}</strong></CCol>
      <CCol>
        <div class="form-inline">
          <CInputCheckbox
            class="mx-1"
            v-for="(option, n) in roomFeatureList"
            :key="n + option"
            :label="option.feature.name"
            :value="option.feature.id"
            custom
            :checked.sync="option.enabled"
          />
        </div>
      </CCol>
    </CRow>
    <!-- #endregion 部屋特徴 -->

    <!-- #region 部屋設備 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.equipment") }}</strong></CCol>
      <CCol>
        <div class="form-inline">
          <CInputCheckbox
            class="mx-1"
            v-for="(option, n) in roomEquipmentList"
            :key="n + option"
            :label="option.feature.name"
            :value="option.feature.id"
            custom
            :checked.sync="option.enabled"
          />
        </div>
      </CCol>
    </CRow>
    <!-- #endregion 部屋設備 -->

    <!-- #region 客室アメニティ-->
    <CRow>
      <CCol class="label"><strong>{{ $t("roomTypeEditPage.amenity") }}</strong></CCol>
      <CCol>
        <div class="form-inline">
          <CInputCheckbox
            class="mx-1"
            v-for="(option, n) in amenityList"
            :key="n + option"
            :label="option.feature.name"
            :value="option.feature.id"
            custom
            :checked.sync="option.enabled"
          />
        </div>
      </CCol>
    </CRow>
    <!-- #endregion 客室アメニティ -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="info"
      @click="reqPut()"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->
    <!-- #endregion Form -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import axios from "axios";
import FEATURE_TYPE from "@/mixins/property";

export default {
  name: "RoomTypeEditPosting",

  mixins: [FEATURE_TYPE],

  components: {},

  props: {},

  computed: {
    roomTypeId() {
      return this.$route.params.roomTypeId;
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Edit
      edit: {},
      roomTypeList: [],
      roomCategoryList: [],
      roomFeatureList: [],
      roomEquipmentList: [],
      amenityList: [],
      //#endregion Edit


      //#region Response
      response: [],
      //#endregion Response


      //#region Message
      errorMessage: "",
      //#endregion Message
    };
  },

  methods: {
    //#region Event
    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },
    //#endregion Event


    //#region Request
    /** 部屋タイプのプロパティ設定を取得 */
    reqGet() {
      // const url = `/rest/hotel/${this.uid}/room/${this.roomTypeId}`;
      // const url = `/rest/room/${this.roomTypeId}/feature`;
      const url = `/rest/hotel/${this.uid}/room/${this.roomTypeId}/feature`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log("reqGet(): ", JSON.stringify(resp.data));

          const filterFeature = (type) => resp.data.features.filter(a => a.feature.type === type);

          this.roomTypeList = filterFeature(this.FEATURE_TYPE.ROOM_TYPE);
          this.roomCategoryList = filterFeature(this.FEATURE_TYPE.ROOM_CATEGORY);
          this.roomFeatureList = filterFeature(this.FEATURE_TYPE.ROOM_FEATURE);
          this.roomEquipmentList = filterFeature(this.FEATURE_TYPE.ROOM_EQUIPMENT);
          this.amenityList = filterFeature(this.FEATURE_TYPE.AMENITY);
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    /** 部屋タイプのプロパティ設定を更新 */
    reqPut() {
      this.loading = true;

      // チェックONの
      const idList = (a) => a.filter(b => b.enabled).map(b => b.feature.id);
      const list = [
        ...idList(this.roomTypeList),
        ...idList(this.roomCategoryList),
        ...idList(this.roomFeatureList),
        ...idList(this.roomEquipmentList),
        ...idList(this.amenityList),
      ];

      const url = `/rest/hotel/${this.uid}/room/${this.roomTypeId}/feature`;
      const body = list;

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.reqGet();
          this.loading = false;
          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },
    //#endregion Request
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>

<style scoped>
.form-inline {
  padding-bottom: 16px;
}
</style>
